import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import productScreenshot from '../../images/screenshot-affiliate-marketing.png';
import PageLayout from '../../layouts/Page';
import { ShieldCheckIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline';
import Testimonials from '../../components/Testimonials';
import TrustUs from '../../components/TrustUs';
import ThankYouSmallLogo from '../../images/small_logo.svg';

const features = [
  {
    name: 'Détection des fraudes',
    description:
      "TY Affiliate étudie le comportement de chaque affilié pour déterminer si les commissions demandées lui sont réellement dûes. La commission est bloquée lorsque l'affilié ne respecte pas les conditions du programme d'affiliation. Simple et efficace.",
    icon: ShieldCheckIcon,
    link: '/affiliate-marketing/detection-fraudes-affiliation',
  },
  {
    name: 'Attribution & Rémunération',
    description:
      "Il est important de rémunérer vos affiliés en fonction de leur valeur ajoutée. Rémunérez différemment un affilié en fin de parcours qu'un affilié vous ayant fait connaitre auprès de l'internaute. Le simulateur de rémunérations vous aidera à prendre les bonnes décisions.",
    icon: ScaleIcon,
    link: '/affiliate-marketing/attribution-remuneration',
  },
  {
    name: 'Validation des ventes',
    description:
      "De la détection de la fraude à la phase de clôture mensuelle, tout est 100% automatisé ! Terminez les multiples exports depuis Analytics et votre régie d'affiliation, accédez à une unique interface synchronisée avec l'ensemble de votre écosystème.",
    icon: LightningBoltIcon,
    link: '/affiliate-marketing/validation-ventes-affiliation',
  },
];

const AffiliatesMarketing = () => {
  return (
    <PageLayout>
      <Helmet>
        <title>Boostez votre programme d'affiliation avec TY Affiliate</title>
        <meta
          name="description"
          content="Réinventons ensemble la gestion de votre programme d'affiliation avec TY Affiliate : Détection des fraudes, Rémunération intelligente, validation des ventes..."
        />
      </Helmet>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="font-title text-3xl font-extrabold tracking-tight text-green-brand">
              <div className="relative flex flex-column justify-center items-center">
                <img
                  className="flex-shrink-0 h-12 w-12 mr-2 text-green-brand"
                  src={ThankYouSmallLogo}
                  alt="Thank-You Analytics Logo"
                />
                <span>Affiliate</span>
              </div>
            </h2>
            <p className="mt-4 max-w-6xl mx-auto font-title font-extrabold text-gray-900 text-2xl sm:tracking-tight lg:text-5xl">
              Boostez votre programme d'affiliation
            </p>
            <p className="max-w-4xl mt-5 mx-auto text-xl text-gray-500">
              Solution complète de pilotage de l'affiliation directement synchronisée avec vos
              plateformes.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white sm:-mb-80">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <img className="" src={productScreenshot} alt="Affiliate Marketing" />
        </div>
      </div>
      <div className="bg-white pb-16 sm:pb-24 pt-16 sm:pt-96">
        <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <p className="max-w-prose mx-auto text-xl text-gray-500">
            En plus d'identifier précisément les fraudes en affiliation, nous mettons en place un
            modèle de rémunération intelligent et vérifions le statut de chacune des ventes.
          </p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="pt-6">
                  <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-gray-700 rounded-md shadow-lg">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <Link
                        to={feature.link}
                        className="block mt-8 text-lg font-medium text-gray-900 tracking-tight"
                      >
                        {feature.name}
                      </Link>
                      <p className="mt-5 text-base text-gray-500">{feature.description}</p>

                      <Link
                        to={feature.link}
                        className="shadow inline-block mt-4 px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-green-brand hover:bg-green-brandHover"
                      >
                        En savoir plus
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <TrustUs />
      <Testimonials />
    </PageLayout>
  );
};

export default AffiliatesMarketing;
